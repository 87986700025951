import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../Layouts/HomeLayout";
import HomeEternia from "../Pages/HomeEternia/Home";
import Somos from "../Pages/Somos/Somos";
import Servicios from "../Pages/Servicios/Servicios";
import EdificioEternia from "../Pages/Edificio/EdificioEternia";
import Contact from "../Pages/Contacto/Contact";
import TerminosCondiciones from "../Pages/Legal/Terminos";
import PoliticaCookies from "../Pages/Legal/Cookies";
import AvisoPrivacidad from "../Pages/Legal/Aviso";
import ErrorPage from "../Pages/ErrorPage";
import Blog from "../Pages/Blog/Blog";
import BlogDetails from "../Pages/Blog/DetailBlog";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        path: "/",
        element: <HomeEternia />,
      },
      {
        path: "/Quienes-Somos",
        element: <Somos />,
      },
      {
        path: "/Servicios-Eternia",
        element: <Servicios />,
      },
      {
        path: "/Edificio-Eternia",
        element: <EdificioEternia />,
      },
      {
        path: "/Contacto",
        element: <Contact />,
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
      {
        path: "/Terminos-Condiciones-Eternia",
        element: <TerminosCondiciones />,
      },
      {
        path: "/Aviso-Privacidad-Eternia",
        element: <AvisoPrivacidad />,
      },
      {
        path: "/Politicas-Cookies-Eternia",
        element: <PoliticaCookies />,
      },
      {
        path: "/Blog/:endpoint",
        element: <Blog />,
      },
      {
        path: "/Articulo-Blog/:article",
        element: <BlogDetails />,
      },
    ],
  },
  {
    path: "/Servicios-Eternia",
    element: <Servicios />,
  },
  {
    path: "/Edificio-Eternia",
    element: <EdificioEternia />,
  },
  {
    path: "/Contacto",
    element: <Contact />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
  {
    path: "/Terminos-Condiciones-Eternia",
    element: <TerminosCondiciones />,
  },
  {
    path: "/Aviso-Privacidad-Eternia",
    element: <AvisoPrivacidad />,
  },
  {
    path: "/Politicas-Cookies-Eternia",
    element: <PoliticaCookies />,
  },
  {
    path: "/Blog/:endpoint",
    element: <Blog />,
  },
  {
    path: "/Articulo-Blog/:article",
    element: <BlogDetails />,
  },
]);
