import React from 'react';
import { Link } from 'react-router-dom';

const DignidadHome = (props) => {
    return (
        <div className="slider" style={{ backgroundImage: `url(${props.bgImg})` }}>
            <div className="slide_content">
                <div className="slide_content_wrapper mb-0 bg-dark-100">
                    <div className="slide_content_inner">
                        <div className="meta m-0">
                            <div className="category text-uppercase">NUESTROS NICHOS</div>
                        </div>
                        <h4 className='mBold'>{props.title}</h4>
                        <p className="mRegular fz-20">
                            En ETERNIA nos dedicamos apasionadamente a 
                            ofrecer soluciones de nichos que reflejan la dignidad 
                            y el respeto que merecen nuestros seres queridos en su 
                            última morada. 
                        </p>
                        <div className="details_link">
                            <Link to="/Servicios-Eternia">
                                <span className="link_text">Conoce NUESTROS NICHOS</span>
                                <span className="link_icon">
                                    <span className="line"></span>
                                    <span className="circle"></span>
                                    <span className="dot"></span>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DignidadHome;