import React from 'react';
import { Link } from 'react-router-dom';

const SliderSomosCard = (props) => {
    return (
        <div className="slider" style={{ backgroundImage: `url(${props.bgImg})` }}>
            <div className="container-fluid p-0">
                <div className="slide_content">
                    <div className="slide_content_wrapper mb-0 h-auto">
                        <div className="slide_content_inner">
                            <div className="row">
                                <div className="col-lg-1"></div>
                                <div className="col-lg-7">
                                    <div className="">
                                        <h4 className='bRegular fz-60 text-uppercase'><a>{props.title}</a></h4>
                                        <p className="mRegular fz-24">
                                            Nuestros espacios ofrecen elegancia, cuidado y la
                                        </p>
                                        <p className="mRegular fz-24">
                                                atención al detalle.
                                        </p>
                                        <div className="details_link">
                                            <Link to="/Contacto">
                                                <span className="link_text">CONOCE MÁS</span>
                                                <span className="link_icon">
                                                    <span className="line"></span>
                                                    <span className="circle"></span>
                                                    <span className="dot"></span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="mask-content" > 
                            <img src={props.bgImg02} alt="Ilustración del edificio de Eternia" />  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SliderSomosCard;