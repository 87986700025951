import React from 'react';

const AsideInfo = () => {
    return (
        <div className="aside_info_wrapper">
            <button className="aside_close"><i className="bi bi-x-lg"></i></button>
            <div className="aside_logo">
                <a href="/" className="light_logo"><img src="images/logo-light.svg" alt="logo" /></a>
                <a href="/" className="dark_logo"><img src="images/logo-dark.svg" alt="logo" /></a>
            </div >
            <div className="aside_info_inner">
                <p className='mRegular fz-20'>
                    Soluciones de nichos funerarios que reflejan la dignidad y el respeto que merecen
                    nuestros seres queridos.
                </p>

                <div className="aside_info_inner_box">
                    <h5 className='mRegular txt-oro'>Contacto</h5>
                    <p className='mRegular'>
                        <a href="tel:8004722767" target="_blank" rel="noopener noreferrer"> 800 472 2767</a>
                    </p>
                    <p className='mRegular'>
                        <a href="https://api.whatsapp.com/send?phone=5586398141" target="_blank" rel="noopener noreferrer">55 8639 8141</a>
                    </p>
                    <p className='mRegular'>
                        <a href="https://maps.app.goo.gl/Ep9DqmZvRoWQTwXA9" target="_blank" rel="noopener noreferrer">
                            Av. Miguel Hidalgo Ote. #1003, C.P: 50080, Barrio de San Bernandino Toluca de Lerdo, México
                        </a>
                    </p>
                    <p className='mRegular'>
                        <a href="mailto:contacto@eternia.mx" target="_blank" rel="noopener noreferrer">contacto@eternia.mx</a>
                    </p>
                </div>
                <div className="social_sites">
                    <ul className="d-flex align-items-center justify-content-center">
                        <li><a href='https://www.facebook.com/eternia1.mx/' target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a></li>
                        <li><a href='https://www.tiktok.com/@eterniamx' target="_blank" rel="noopener noreferrer"><i className="bi bi-tiktok"></i></a></li>
                        <li><a href='https://www.instagram.com/eterniamx/' target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div >

    );
};

export default AsideInfo;